<template>
  <el-dialog
    :visible.sync="visible"
    title="服务器初始化配置"
    custom-class="ql-dialog"
    width="636px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <el-form :model="form" :rules="rules" ref="form" label-width="140px" size="small">
      <el-form-item label="教育局编码" prop="educationKey">
        <el-input v-model="form.educationKey"></el-input>
      </el-form-item>
      <el-form-item label="初始化服务器地址" prop="hostUrl">
        <el-input v-model="form.hostUrl"></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer">
      <el-button type="primary" size="small" @click="handleSave">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
/**
 * @desc 初始化教育局
 */
// components
import { Button, Dialog, Input, Form, FormItem } from 'element-ui';
// api
import { saveInitEducation } from '@/api/back-stage';

export default {
  name: 'school-server-config',
  components: {
    [Button.name]: Button,
    [Dialog.name]: Dialog,
    [Input.name]: Input,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
  },
  data() {
    return {
      visible: true,
      form: {
        educationKey: '',
        hostUrl: '',
      },
      rules: {
        educationKey: [{ required: true, message: '请填写教育局编码', trigger: 'blur' }],
        hostUrl: [{ required: true, message: '请填写初始化服务器地址', trigger: 'blur' }],
      },
    };
  },
  methods: {
    // 保存
    handleSave() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          saveInitEducation(this.form).then(() => {
            this.$router
              .push('/back-stage')
              .then(() => {})
              .catch(() => {});
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
};
</script>
